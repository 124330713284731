<template>
  <button
    v-if="!href"
    :class="componentClass"
    @click="onClick"
    :type="type"
    :disabled="disabled"
    v-tw-merge
  >
    <DefaultSpinner v-if="isLoading"></DefaultSpinner>
    <slot v-if="!isLoading"></slot>
  </button>
  <NuxtLink
    v-if="href && href[0] === '/'"
    :to="href"
    :class="componentClass"
    @click="onClick"
    v-tw-merge
  >
    <slot></slot>
  </NuxtLink>
  <a
    v-if="href && href[0] !== '/'"
    :href="href"
    :class="componentClass"
    @click="onClick"
    :target="target"
    v-tw-merge
  >
    <slot></slot>
  </a>
</template>

<script lang="ts" setup>
const props = defineProps({
  class: [String, Object],
  href: String,
  type: {
    type: String as PropType<"reset" | "submit" | "button">,
    default: "button",
  },
  color: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    default: "full",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  itemPosition: {
    type: String,
    default: "center",
  },
  buttonClass: {
    type: String,
    default:
      "rounded-xl text-lg leading-7 font-semibold disabled:opacity-50 disabled:cursor-not-allowed",
  },
  target: {
    type: String,
    default: "_self",
  },
});

const emit = defineEmits(["click"]);

const onClick = () => {
  emit("click");
};

const componentColor = computed(() => {
  switch (props.color) {
    case "primary":
      return "bg-white text-gray-900";
    case "outline":
      return "bg-transparent text-white border border-white";
    case "transparent":
      return "bg-transparent text-white";
    case "green":
      return "bg-green-700 text-white";
    case "delete":
      return "bg-red-700 text-white";
    case "outline-gray":
      return "bg-transparent text-white border border-gray-900";
    case "outline-red":
      return "bg-transparent text-red-500 border border-red-500";
    case "lime":
      return "bg-lime-300 text-gray-900";
    case "gray":
      return "bg-gray-600 text-white bg-opacity-20";
    default:
      return "";
  }
});

const componentSize = computed(() => {
  switch (props.size) {
    case "full":
      return "w-full h-[76px] px-5";
    case "fit":
      return "w-fit";
    case "compact":
      return "w-full py-4 px-4";
    default:
      return "";
  }
});

const componentItemPosition = computed(() => {
  switch (props.itemPosition) {
    case "center":
      return "flex items-center justify-center";
    case "none":
      return "";
    default:
      return "";
  }
});

const componentClass = computed(() => {
  return [
    componentColor.value,
    componentItemPosition.value,
    componentSize.value,
    props.buttonClass,
    props.class,
  ];
});
</script>
